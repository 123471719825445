import axios from 'axios'
import { BASE_URL } from '../config/config'
import { getToken, getCSRFToken } from '../helpers/index'

const instance = axios.create({
  baseURL: BASE_URL,
})

instance.interceptors.request.use(
  (config) => {
    const token = getToken()
    const CSRF_TOKEN = getCSRFToken()
    if (token) config.headers['Authorization'] = 'Bearer ' + token
    if (CSRF_TOKEN) config.headers['X-XSRF-TOKEN'] = CSRF_TOKEN
    config.withCredentials = true
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('authToken')
      window.location.href = '/'
    }
    return Promise.reject(error)
  },
)

export default instance
