export const Register = '/register'
export const Login = '/login'
export const Page404 = '/404'
export const Page500 = '/505'
export const Dashboard = '/dashboard'

export const SettingList = '/setting/list'
export const AddSetting = '/setting/add'
export const EditSetting = '/setting/edit'
export const EditSettingId = '/setting/edit/:id'

export const UserList = '/user/list'
export const AddUser = '/user/add'
export const EditUser = '/user/edit'
export const EditUserId = '/user/edit/:id'
export const ViewPermission = '/user/permission/:id'

export const QuestionsList = '/exam/questions/list'
export const AddBulkQuestions = '/exam/questions/bulk'

export const ExamResult = '/exam/result'
