// * React Hooks
import React, { lazy, Suspense, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

// * Routes
import * as URL from './Routes'
import ProtectedRoute from 'src/helpers/ProtectedRoute'

// * Pages
const Login = lazy(() => import('../views/pages/Login/Login'))
const Register = lazy(() => import('../views/pages/register/Register'))
const Page404 = lazy(() => import('../views/pages/page404/Page404'))
const Page505 = lazy(() => import('../views/pages/page500/Page500'))
const Dashboard = lazy(() => import('../views/dashboard/Dashboard'))

const SettingList = lazy(() => import('../views/pages/Settings/List'))

const UserList = lazy(() => import('../views/pages/Users/List'))

const PermissionView = lazy(() => import('../views/pages/Users/View/View'))

const QuestionsList = lazy(() => import('../views/pages/Exams/List'))
const AddBulkQuestions = lazy(() => import('../views/pages/Exams/Bulk/Bulk'))

const ExamResult = lazy(() => import('../views/pages/Result/List'))

// * Page Layout Component
const Layout = lazy(() => import('../layout/Layout'))

// * Loader
const Loader = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

export default function Router() {
  const Navigate = useNavigate()
  useEffect(() => {
    if (window.location.pathname === '/') Navigate('/login')
  })
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path={URL.Login}
          element={
            <ProtectedRoute accessBy="non-authenticated">
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.Dashboard}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<Dashboard />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.SettingList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<SettingList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.UserList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<UserList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewPermission}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<PermissionView />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.QuestionsList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<QuestionsList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddBulkQuestions}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddBulkQuestions />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ExamResult}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<ExamResult />} />
            </ProtectedRoute>
          }
        />

        <Route path={URL.Register} element={<Register />} />
        <Route path={URL.Page500} element={<Page505 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  )
}
